<template>
  <section class="dashboard">
    <div class="page-header">
      <h3 class="page-title">
      <span class="page-title-icon bg-gradient-info text-white mr-2">
        <i class="mdi mdi-home"></i>
      </span> Dashboard </h3>
      <!-- <nav aria-label="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <span></span>Overview <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
          </li>
        </ul>
      </nav> -->
    </div>
    <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="mdi mdi-account-multiple text-info icon-lg"></i>
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">Players</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{getPlayers.length}}</h3>
                  </div>
                </div>
              </div>
              <!-- <p class="text-muted mt-3 mb-0">
                <i class="mdi mdi-alert-octagon mr-1" aria-hidden="true"></i> 65% lower growth </p> -->
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="mdi mdi-forum text-warning icon-lg"></i>
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">Jobs</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ getJobs.length }}</h3>
                  </div>
                </div>
              </div>
              <!-- <p class="text-muted mt-3 mb-0">
                <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> Product-wise sales </p> -->
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="fa fa-taxi text-success icon-lg"></i>
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">Stations</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ getStations.length }}</h3>
                  </div>
                </div>
              </div>
              <!-- <p class="text-muted mt-3 mb-0">
                <i class="mdi mdi-calendar mr-1" aria-hidden="true"></i> Weekly Sales </p> -->
            </div>
          </div>
        </div>
      </div>
  </section>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'dashboard',
  components: {

  },
  data() {
    return {
      time1: null,
      msg: null
    };
  },
  computed: {
    ...mapGetters(['getPlayers', 'getJobs', 'getStations'])
  },
  methods: {
    ...mapActions(['setPlayers', 'fetchJobs', 'fetchStations'])
  },
  created(){
    
  },
  async mounted() {
    await this.setPlayers();
    await this.fetchJobs();
    await this.fetchStations();
  }
}
</script>

<style scoped>

</style>